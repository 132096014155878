import { ENDPOINTS, get, put } from '@/api/mainApi';
import Vue from 'vue';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { AxiosError } from 'axios';
import { PaginatedResponse } from '@/interfaces/paginatedResponse';
import { Depot } from '@/interfaces/depot/depot';

export class DepotRepository {
    constructor(private vueInstance: Vue) {
    }

    public async getDepots(
      statuses: string,
      page: number,
      limit: number,
      excludeToBeArchived: boolean | null,
      onlySignatureMissing: boolean | null,
      onlyFilesMissing: boolean | null,
      sortValue: string,
      sortOrder: string
    ): Promise<PaginatedResponse | Array<Depot>> {
      try {
        return await get(`${ENDPOINTS.DEPOTS}`, {
          statuses: statuses,
          page: page,
          limit: limit,
          excludeToBeArchived: excludeToBeArchived,
          onlySignatureMissing: onlySignatureMissing,
          onlyFilesMissing: onlyFilesMissing,
          sortValue: sortValue,
          sortOrder: sortOrder
        });
      } catch (error) {
        this.handleError(error, `Misslyckades med att hämta depåer: ${error.data.msg || 'Okänt fel'}`);
        return Promise.reject(error);
      }
    }

    public async updateDepotName(depotId: number, depotName: string): Promise<string> {
        try {
            const response = await put(`${ENDPOINTS.DEPOTS}/${depotId}/${ENDPOINTS.NAME}/${depotName}`, {});
            this.setSnackbar('Uppdaterade namnet på depån', 'success');
            return response;
        } catch (error) {
            this.handleError(error, `Misslyckades med att uppdatera depånamnet: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

    private setSnackbar(message: string, color: 'error' | 'success') {
        const snackbar: Snackbar = {
            active: true,
            color: color,
            text: message,
            timeout: 10000,
            action: null,
        };
        this.vueInstance.$store.commit('setSnackbar', snackbar);
    }

    private handleError(error: AxiosError, errorMessage: string) {
        if (error.response?.status === 403) {
            this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
        } else {
            this.setSnackbar(errorMessage, 'error');
        }
    }
}
