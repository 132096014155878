










































import Vue from "vue";
import {TableOptions} from "@/interfaces/Vuetify";
import { Depot, ShortenedStatusStr, Status, StatusColor, StatusStr } from '@/interfaces/depot/depot';
import {PaginatedResponse} from "@/interfaces/paginatedResponse";
import { DepotRepository } from '@/repositories/depotRepository';
import dayjs from "dayjs";
import { EventLog } from '@/interfaces/eventlog/eventLog';

export default Vue.extend({
  props: {
    filteredStatuses: {
      type: Array as () => Status[] | null,
      default: null
    },
    excludeToBeArchived: Boolean,
    onlySignatureMissing: Boolean,
    onlyFilesMissing: Boolean,
  },
  data() {
    return {
      loading: false,
      statusStr: StatusStr,
      shortenedStatusStr: ShortenedStatusStr,
      statusColor: StatusColor,
      depots: [] as Array<Depot>,
      depotRepository: new DepotRepository(this),
      page: 1,
      pageCount: 1,
      totalRowCount: 0,
      itemsPerPage: 100,
      sortValue: "statusUpdatedAt",
      sortOrder: "desc",
      footerProps: {
        "items-per-page-all-text": "alla",
        "items-per-page-options": [100, 250, 500],
        "items-per-page-text": "Rader per sida"
      },
      tableHeaders: [
        {
          text: 'Namn',
          value: "name",
          align: 'left',
          sortable: true,
        },
        {
          text: 'Personnummer',
          value: "socialSecurityNumber",
          align: 'left',
          sortable: true,
        },
        {
          text: 'Status',
          value: "status",
          align: 'left',
          sortable: true,
        },
        {
          text: 'Status ändrades',
          value: "statusUpdatedAt",
          align: 'left',
          sortable: true,
        },
        {
          text: 'Senaste händelse',
          value: "lastEventCreatedAt",
          align: 'left',
          sortable: false,
        },
      ],
      optionsProps: {
        sortBy: ["statusUpdatedAt"],
        sortDesc: [true],
        multiSort: false
      } as TableOptions,
    };
  },
  computed: {
    filteredStatusesParam(): string {
      return this.filteredStatuses && this.filteredStatuses.length > 0
        ? this.filteredStatuses.join(",")
        : (this.onlySignatureMissing || this.onlyFilesMissing) ? null : '';
    }
  },
  watch: {
    filteredStatuses(val) {
      this.getDepots();
    },
    page() {
      this.getDepots()
    },
  },
  filters: {
    statusStr(status, statusStr) {
      return statusStr[status]
    },
    shortenedStatusStr(status, shortenedStatusStr) {
      return shortenedStatusStr[status]
    }
  },
  created() {
    this.getDepots();
  },
  methods: {
    async getDepots() {
      this.loading = true;
      const res: PaginatedResponse | Array<Depot> = await this.depotRepository.getDepots(this.filteredStatusesParam, this.page,
        this.itemsPerPage, this.excludeToBeArchived ?? null, this.onlySignatureMissing ?? null,
        this.onlyFilesMissing ?? null, this.sortValue, this.sortOrder);
      if (res instanceof Array) {
        this.depots = res;
        this.totalRowCount = res.length;
        this.pageCount = 1;
      } else {
        this.totalRowCount = res.totalRowCount
        this.pageCount = res.totalPageCount
        this.depots = res.data;
      }
      this.massageData();
      this.loading = false;
    },
    sortBy(val: string | undefined) {
      if (!!val) {
        this.sortValue = val;
      }
    },
    sortDesc(val: boolean | undefined) {
      this.sortOrder = !!val ? "desc" : "asc";
      this.getDepots();
    },
    dateTimeFormat(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm")
    },
    dateFormat(date) {
      return dayjs(date).format("YYYY-MM-DD")
    },
    massageData() {
      this.depots.forEach(d => {
        d.lastEventCreatedAt = this.getLastEventCreatedAt(d);
        d.createdAt = this.dateFormat(d.createdAt);
        d.statusUpdatedAt = this.dateFormat(d.statusUpdatedAt);
      })
    },
    selectRow(depot: Depot) {
      this.loading = true;
      this.$router.push({name: 'depotDetails', params: {id: depot.id}})
    },
    getLastEventCreatedAt(depot: Depot): string {
      if (!depot.eventLogs) {
        return "";
      }

      let lastEventCreatedAt = "";
      depot.eventLogs.forEach((e: EventLog) => {
        if (!e.excludedFromHomepage && e.createdAt > lastEventCreatedAt) {
          lastEventCreatedAt = e.createdAt;
        }
      })

      return this.dateTimeFormat(lastEventCreatedAt);
    }
  }
});
