import { render, staticRenderFns } from "./DepotDataTableComponent.vue?vue&type=template&id=55db6b4a&scoped=true&"
import script from "./DepotDataTableComponent.vue?vue&type=script&lang=ts&"
export * from "./DepotDataTableComponent.vue?vue&type=script&lang=ts&"
import style0 from "./DepotDataTableComponent.vue?vue&type=style&index=0&id=55db6b4a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55db6b4a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VDataTable,VTooltip})
